<template>
  <div class="dashboard">
    <CRow>
      <CCol sm="12">
        <CCard class="mb-4">
          <CCardBody>
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h4>Willkommen, {{ $commons.getCurrentRealm().name }}!</h4>
              <CDropdown
                :toggler-text="dashboardMetricsPeriod"
                color="primary"
                class="dashboard-dropdown"
              >
                <CDropdownHeader>Standard Zeiträume</CDropdownHeader>
                <CDropdownItem 
                  v-for="period in periods" 
                  :key="period" 
                  @click="setDashboardMetricsPeriod(period)"
                  :active="dashboardMetricsPeriod === period"
                >
                  {{ period }}
                </CDropdownItem>
                <CDropdownDivider />
                <CDropdownHeader>Spezielle Zeiträume</CDropdownHeader>
                <CDropdownItem 
                  v-for="specialPeriod in specialPeriods" 
                  :key="specialPeriod" 
                  @click="setDashboardMetricsPeriod(specialPeriod)"
                  :active="dashboardMetricsPeriod === specialPeriod"
                >
                  {{ specialPeriod }}
                </CDropdownItem>
              </CDropdown>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- Fortschrittsanzeige für das Laden im Hintergrund -->
    <CAlert v-if="!backgroundDataLoaded && loadingProgress > 0 && loadingProgress < 100" color="info" class="loading-alert">
      <CIcon name="cil-storage" class="me-2" />
        Aktualisiere Daten für alle Zeiträume... {{ Math.round(loadingProgress) }}%
      <CProgress
        :value="loadingProgress"
        class="mt-2"
        height="4"
      />
    </CAlert>

    <CRow>
      <CCol sm="3" v-for="(metric, index) in keyMetrics" :key="index">
        <metric-card
          :title="metric.title"
          :value="metric.value"
          :description="metric.description"
          :icon="metric.icon"
          :color="metric.color"
        />
      </CCol>
    </CRow>

    <CRow class="mt-4">
      <CCol sm="6">
        <CCard>
          <CCardHeader>
            <strong>Lead-Generierung Trend</strong>
          </CCardHeader>
          <CCardBody>
            <line-chart :chart-data="leadTrendData" :options="lineChartOptions" />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="6">
        <CCard>
          <CCardHeader>
            <strong>Lead-Quellen</strong>
          </CCardHeader>
          <CCardBody>
            <pie-chart :chart-data="leadSourceData" :options="pieChartOptions" />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow class="mt-4">
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Leads auf der Karte</strong>
          </CCardHeader>
          <CCardBody>
            <lead-map :markers="markers" :center="center" />
           </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Hinzufügen des Overlay für die Datenaktualisierung -->
    <div v-if="isUpdating" class="update-overlay">
      <div class="update-message">
        <i class="cil-sync spinning"></i> Daten werden aktualisiert...
      </div>
    </div>


  </div>
</template>

<script>
import MetricCard from '@/components/MetricCard.vue'
import LeadMap from '@/components/LeadMap.vue'
import LineChart from '@/components/LineChart.vue'
import PieChart from '@/components/PieChart.vue'
import axios from "axios"
import moment from "moment"
import commons from "@/commons"
import { CCard, CCardTitle, CCardHeader, CCardBody, CRow, CCol, CDropdown, CDropdownItem, CDropdownDivider, CDropdownHeader, CAlert, CIcon, CProgress } from '@coreui/vue'

export default {
  name: 'Dashboard',
  components: {
    CCard, CCardTitle, CCardHeader, CCardBody, CRow, CCol, CDropdown, CDropdownItem, CDropdownDivider, CDropdownHeader, CAlert, CIcon, CProgress,
    MetricCard,
    LeadMap,
    LineChart,
    PieChart
  },
  data() {
    return {
      center: { lat: 50.90055852891332, lng: 10.463925212353091 },
      cardData: {
        leadTrend: [],
        leadSources: {}
      },
      isUpdating: false,
      isLoading: true,
      cachedData: {},
      loadingProgress: 0,
      totalPeriods: 0,
      backgroundDataLoaded: false,
      markers: [],
      dashboardMetricsPeriod: commons.getFromStorage('dashboardMetricsPeriod', 'Letzte 7 Tage'),
      periods: ['Letzte 24 Stunden', 'Letzte 7 Tage', 'Letzte 14 Tage', 'Letzte 30 Tage', 'Letzte 3 Monate', 'Letzte 6 Monate', 'Letzte 12 Monate'],
      specialPeriods: ['Dieser Tag', 'Diese Woche', 'Dieser Monat', 'Dieses Jahr', 'Alle'],
      dashboardMetricsPeriodMap: {
        'Letzte 24 Stunden': () => ({from: moment().subtract(24, 'hours'), to: moment()}),
        'Letzte 7 Tage': () => ({from: moment().subtract(7, 'days'), to: moment()}),
        'Letzte 14 Tage': () => ({from: moment().subtract(14, 'days'), to: moment()}),
        'Letzte 30 Tage': () => ({from: moment().subtract(30, 'days'), to: moment()}),
        'Letzte 3 Monate': () => ({from: moment().subtract(3, 'months'), to: moment()}),
        'Letzte 6 Monate': () => ({from: moment().subtract(6, 'months'), to: moment()}),
        'Letzte 12 Monate': () => ({from: moment().subtract(12, 'months'), to: moment()}),
        'Dieser Tag': () => ({from: moment().startOf('day'), to: moment().endOf('day')}),
        'Diese Woche': () => ({from: moment().startOf('week'), to: moment().endOf('week')}),
        'Dieser Monat': () => ({from: moment().startOf('month'), to: moment().endOf('month')}),
        'Dieses Jahr': () => ({from: moment().startOf('year'), to: moment().endOf('year')}),
        'Alle': () => ({from: moment(0), to: moment()}),
      },
      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'category',
            title: {
              display: true,
              text: 'Zeitraum'
            }
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Anzahl der Leads'
            }
          }
        }
      },
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right',
          }
        }
      },
      isUpdating: false,
      isLoading: true,
      cachedData: {},
    }
  },
  computed: {
    keyMetrics() {
      return [
        {
          title: 'Gesamte Leads',
          value: this.cardData.totalLeads || 0,
          description: 'Seit Beginn',
          icon: 'cil-people',
          color: 'info'
        },
        {
          title: 'Leads im Zeitraum',
          value: this.cardData.leads || 0,
          description: this.dashboardMetricsPeriod,
          icon: 'cil-user-follow',
          color: 'success'
        },
        {
          title: 'Leads mit PDF',
          value: this.cardData.leadsWithPdf || 0,
          description: 'Im gewählten Zeitraum',
          icon: 'cil-file',
          color: 'warning'
        },
        {
          title: 'Potentieller Gewinn',
          value: this.$commons.formatMoney(this.cardData.valueOfLeads * 0.05),
          description: 'Bei 5% Provision',
          icon: 'cil-dollar',
          color: 'danger'
        }
      ]
    },
    leadTrendData() {
      if (!this.cardData.leadTrend || this.cardData.leadTrend.length === 0) {
        return {
          labels: [],
          datasets: [{
            label: 'Leads',
            data: [],
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }]
        };
      }

      const periodFunction = this.dashboardMetricsPeriodMap[this.dashboardMetricsPeriod];
      const period = periodFunction();
      const fromDate = period.from;
      const toDate = period.to;

      const filteredTrend = this.cardData.leadTrend.filter(item => {
        const itemDate = moment(item.date);
        return itemDate.isSameOrAfter(fromDate) && itemDate.isSameOrBefore(toDate);
      });

      const sortedTrend = filteredTrend.sort((a, b) => new Date(a.date) - new Date(b.date));

      let aggregatedData;
      if (['Letzte 3 Monate', 'Letzte 6 Monate', 'Letzte 12 Monate', 'Dieses Jahr', 'Alle'].includes(this.dashboardMetricsPeriod)) {
        // Monatliche Aggregation
        aggregatedData = sortedTrend.reduce((acc, curr) => {
          const monthYear = moment(curr.date).format('YYYY-MM');
          if (!acc[monthYear]) {
            acc[monthYear] = { date: monthYear, count: 0 };
          }
          acc[monthYear].count += curr.count;
          return acc;
        }, {});
        aggregatedData = Object.values(aggregatedData);
      } else {
        // Tägliche Daten
        aggregatedData = sortedTrend;
      }

      const labels = aggregatedData.map(item => 
        this.dashboardMetricsPeriod === 'Letzte 24 Stunden' 
          ? moment(item.date).format('HH:mm') 
          : moment(item.date).format('DD MMM')
      );
      const data = aggregatedData.map(item => item.count);

      return {
        labels: labels,
        datasets: [{
          label: 'Leads',
          data: data,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      };
    },
    leadSourceData() {
      if (!this.cardData.leadSources || Object.keys(this.cardData.leadSources).length === 0) {
        return {
          labels: ['Keine Daten'],
          datasets: [{
            data: [1],
            backgroundColor: ['#CCCCCC']
          }]
        };
      }

      let sources = {...this.cardData.leadSources};
      
      if ('unknown' in sources) {
        sources['Diverse Quellen'] = sources['unknown'];
        delete sources['unknown'];
      }

      const threshold = 0.05;
      const total = Object.values(sources).reduce((sum, value) => sum + value, 0);
      let others = 0;
      
      Object.keys(sources).forEach(key => {
        if (sources[key] / total < threshold) {
          others += sources[key];
          delete sources[key];
        }
      });

      if (others > 0) {
        sources['Sonstige'] = others;
      }

      const labels = Object.keys(sources);
      const data = Object.values(sources);

      return {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40']
        }]
      }
    },
  },
  watch: {
    dashboardMetricsPeriod(newVal) {
      commons.setToStorage('dashboardMetricsPeriod', newVal)
      this.fetchMetrics()
    }
  },
  async created() {
    await this.fetchMetrics(this.dashboardMetricsPeriod);
    this.isLoading = false;
    this.loadOtherPeriodsInBackground();
  },
  methods: {
    setDashboardMetricsPeriod(period) {
      this.dashboardMetricsPeriod = period;
      this.fetchMetrics(period);
    },
    async fetchMetrics(period = this.dashboardMetricsPeriod, cache = false) {
      if (!cache && this.cachedData[period]) {
        this.updateDashboardData(this.cachedData[period]);
        return;
      }

      if (!cache) {
        this.isUpdating = true;
      }
      
      const periodFunction = this.dashboardMetricsPeriodMap[period];
      if (typeof periodFunction !== 'function') {
        console.error(`Invalid period: ${period}`);
        this.isUpdating = false;
        return;
      }
      const { from, to } = periodFunction();
      
      try {
        const response = await axios.get(`/dashboard/metrics`, { 
          params: { 
            key: period, 
            from: from.toISOString(), 
            to: to.toISOString() 
          } 
        });
        
        const data = response.data || {};
        this.cachedData[period] = data;
        localStorage.setItem('dashboardCache', JSON.stringify(this.cachedData));
        
        if (!cache) {
          this.updateDashboardData(data);
        }
      } catch (err) {
        console.error('Problem while fetching data from dashboard metrics', err);
      } finally {
        if (!cache) {
          this.isUpdating = false;
        }
      }
    },
    updateDashboardData(data) {
      this.cardData = data;
      this.cardData.totalLeads = this.cardData.totalLeads || '0';
      this.cardData.leads = this.cardData.leads || '0';
      this.cardData.valueOfLeads = this.cardData.valueOfLeads || 0;
      this.cardData.leadTrend = data.leadTrend || [];
      this.cardData.leadSources = this.cardData.leadSources || {};
      this.markers = this.cardData.leadAddresses ? this.cardData.leadAddresses.map(it => ({
        id: it.id,
        position: {
          lat: it.latitude,
          lng: it.longitude
        },
        name: it.name,
        email: it.email,
        phone: it.phone,
        createdAt: moment(it.createdAt).format('DD.MM.YYYY HH:mm'),
        source: it.source
      })) : []
    },
    async loadOtherPeriodsInBackground() {
    if (this.backgroundDataLoaded) return;

    const periodsToLoad = [...this.periods, ...this.specialPeriods.filter(period => period !== 'Alle')];
    this.totalPeriods = periodsToLoad.length;
    let loadedPeriods = 0;

    for (const period of periodsToLoad) {
      if (period !== this.dashboardMetricsPeriod) {
        await this.fetchMetrics(period, true);
        loadedPeriods++;
        this.loadingProgress = (loadedPeriods / this.totalPeriods) * 100;
      }
    }

    this.backgroundDataLoaded = true;
    this.loadingProgress = 0;
  },
  }
}
</script>


<style scoped>
.dashboard {
  padding: 20px;
}

.dashboard-dropdown .dropdown-item.active {
  background-color: #007bff;
  color: white;
}

.dashboard-dropdown .dropdown-header {
  font-weight: bold;
  color: #6c757d;
}

.update-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.update-message {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  font-size: 18px;
}

.spinning {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loading-alert {
  margin-bottom: 20px;
}

</style>
