<template>
  <div class="lead-map">
    <div class="map-instructions" v-if="showInstructions">
      <p>Klicken Sie auf die Marker, um Details anzuzeigen.</p>
      <button @click="dismissInstructions" class="dismiss-btn">Verstanden</button>
    </div>

    <GmapMap
      :center="center"
      :zoom="7"
      style="width: 100%; height: 400px;"
    >
      <GmapMarker
        v-for="(m, index) in markers"
        :key="index"
        :position="m.position"
        @click="handleClick(m)"
        :icon="customMarkerIcon"
      />
    </GmapMap>

    <transition name="slide-fade">
      <div v-if="isPanelVisible" class="details-panel">
        <h5 class="panel-title">Lead Details</h5>
        <div v-if="selectedMarker" class="lead-info">
          <p><strong>Name:</strong> {{ selectedMarker.name }}</p>
          <p><strong>Email:</strong> {{ selectedMarker.email }}</p>
          <p><strong>Telefon:</strong> {{ selectedMarker.phone }}</p>
          <p><strong>Erstellt am:</strong> {{ selectedMarker.createdAt || 'Datum unbekannt' }}</p>
          <p><strong>Quelle:</strong> {{ selectedMarker.source }}</p>
        </div>
        <button @click="closePanel" class="close-btn">Schließen</button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'LeadMap',
  props: {
    markers: Array,
    center: Object
  },
  data() {
    return {
      selectedMarker: null,
      isPanelVisible: false,
      showInstructions: true,
      customMarkerIcon: {
        path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
        fillColor: '#4285F4',
        fillOpacity: 1,
        strokeWeight: 0,
        scale: 1.5
      }
    };
  },
  methods: {
    formatUnknownValue(value, type) {
      if (value === 'unknown' || value === null || value === undefined) {
        switch (type) {
          case 'name':
            return 'Unbekannter Kontakt';
          case 'email':
            return 'Keine E-Mail angegeben';
          case 'phone':
            return 'Keine Telefonnummer angegeben';
          case 'source':
            return 'Unbekannte Quelle';
          default:
            return 'Nicht verfügbar';
        }
      }
      return value;
    },
    handleClick(marker) {
      console.log('Marker clicked:', marker);
      this.selectedMarker = {
        ...marker,
        name: this.formatUnknownValue(marker.name, 'name'),
        email: this.formatUnknownValue(marker.email, 'email'),
        phone: this.formatUnknownValue(marker.phone, 'phone'),
        source: this.formatUnknownValue(marker.source, 'source')
      };
      this.isPanelVisible = true;
    },
    closePanel() {
      this.isPanelVisible = false;
      this.selectedMarker = null;
    },
    dismissInstructions() {
      this.showInstructions = false;
      localStorage.setItem('mapInstructionsShown', 'true');
    }
  },
  mounted() {
    this.showInstructions = !localStorage.getItem('mapInstructionsShown');
  }
};
</script>

<style scoped>
.lead-map {
  position: relative;
}

.map-instructions {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1;
}

.dismiss-btn {
  background-color: #4285F4;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.details-panel {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  padding: 24px;
  overflow-y: auto;
  border-radius: 12px 0 0 12px;
  transition: all 0.3s ease;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.2em;
  color: #333;
}

.lead-info p {
  margin-bottom: 12px;
  font-size: 0.9em;
  color: #555;
}

.close-btn {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #4285F4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-btn:hover {
  background-color: #3367D6;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
