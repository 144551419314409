<template>
  <CCard :color="color" class="text-white">
    <CCardBody class="pb-0 d-flex justify-content-between align-items-start">
      <div>
        <CCardTitle class="mb-0">{{ title }}</CCardTitle>
        <p class="mb-0">{{ description }}</p>
      </div>
      <CIcon :name="icon" size="xl"/>
    </CCardBody>
    <CCardBody>
      <h3 class="mb-0">{{ value }}</h3>
    </CCardBody>
  </CCard>
</template>

<script>
import { CCard,CCardTitle, CCardHeader, CCardBody, CRow, CCol, CDropdown, CDropdownItem, CDropdownDivider } from '@coreui/vue'

export default {
  name: 'MetricCard',
  components: {
    CCard, CCardTitle, CCardHeader, CCardBody, CRow, CCol, CDropdown, CDropdownItem, CDropdownDivider,
  },
  props: {
    title: String,
    value: [String, Number],
    description: String,
    icon: String,
    color: String
  }
}
</script>
